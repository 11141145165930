'use client'

import IconInsurance from '@icon/house/insurance.svg?url'
import ImgInsurance from '@image/house/insurance.png'
import Image from 'next/image'
import HomeServicesLayout from '../base'
import InsuranceContent from './content'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'

export default function Insurance({
  expand = false
}) {

  function handleToggle(opened = false) {
    if (opened) {
      propertyDataLayer.amiOpened()
    } else {
      propertyDataLayer.amiClosed()
    }
  }

  return (
    <HomeServicesLayout
      title='AMI House Insurance'
      icon={IconInsurance}
      isNew
      right={
        <Image src={ImgInsurance} alt="flooring" className='hidden md:block' unoptimized />
      }
      secondary={
        <div className='pb-10 md:pb-0'>
          <Image src={ImgInsurance} alt="flooring" className='h-45 w-auto -mt-10 block md:hidden' unoptimized />
        </div>
      }
      onToggle={handleToggle}
      open={expand}
    >
      <InsuranceContent />
    </HomeServicesLayout>
  )
}