'use client'

import IconHarMoney from '@icon/house/sloar.svg?url'
import ImgMortgage from '@image/house/sloar.png'
import Image from 'next/image'
import HomeServicesLayout from '../base'
import SloarContent from './content'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'

export default function Sloar({
  regionId,
  districtId,
  expand = false
}) {

  function handleToggle(opened = false) {
    if (opened) {
      propertyDataLayer.solarOpened()
    } else {
      propertyDataLayer.solarClosed()
    }
  }

  return (
    <HomeServicesLayout
      title='Solar Savings calculator'
      icon={IconHarMoney}
      isNew
      right={
        <Image src={ImgMortgage} alt="flooring" className='hidden md:block' unoptimized />
      }
      secondary={
        <div className='pb-10 md:pb-0'>
          <Image src={ImgMortgage} alt="flooring" className='h-40 w-auto -mt-10 block md:hidden' unoptimized />
        </div>
      }
      onToggle={handleToggle}
      open={expand}
    >
      <SloarContent regionId={regionId} districtId={districtId} />
    </HomeServicesLayout>
  )
}