import DisablePrefetchLink from '@component/disablePrefetchLink'
import classNames from 'classnames'
import Image from 'next/image'

export default function SectionLayout({
  title,
  sub,
  titleLink,
  poweredBy,
  titleRight,
  children,
  className,
  contentClassName,
  titleClassName,
  id,
}) {

  const titleLabelClassName = 'text-font text-xl md:text-3xl md:font-bold'

  return (
    <section className={className} id={id}>
      {
        title && (
          <div className={classNames('flex justify-between items-center flex-wrap', titleClassName)}>
            <div>
              {
                titleLink
                  ? <DisablePrefetchLink href={titleLink} target='_blank' className={titleLabelClassName}>{title}<i className='icon icon-arrow-right ml-4 align-middle'></i></DisablePrefetchLink>
                  : <span className={titleLabelClassName}>{title}</span>
              }
              {
                sub && (
                  <div className='text-neutral-5 text-base'>{sub}</div>
                )
              }
            </div>
            {
              poweredBy && <Image src={poweredBy} width={140} height={38} alt={title} className='object-contain object-right h-24 md:h-38'></Image>
            }
            {titleRight}
          </div>
        )
      }
      <div className={classNames('mt-16', contentClassName)}>{children}</div>
    </section>
  )
}