const seventy = 0.7
class Region {
  constructor(label, best, price) {
    this.label = label
    this.best = best
    this.seventy = Math.round(best * seventy)
    this.price = price
  }
}

const systemConfig = {
  1: {
    label: 'Solar Only',
    use: 0.5,
    sell: 0.5,
    sellPrice: 0.12
  },
  2: {
    label: 'Solar with Battery',
    use: 0.75,
    sell: 0.25,
    sellPrice: 0.12
  }
}
const regionConfig = {
  34: new Region('Northland', 578, 0.425),
  35: new Region('Auckland', 622, 0.316),
  36: new Region('Waikato', 593, 0.323),
  37: new Region('Bay of Plenty', 615, 0.364),
  55: new Region('Central North Island', 588, 0.343),
  42: new Region('Wellington', 597, 0.302),
  43: new Region('Nelson', 660, 0.312),
}
const districtConfig = {
  253: new Region('New Plymouth', 638, 0.335),
  282: new Region('Christchurch City', 558, 0.297),
  301: new Region('Wanaka', 633, 0.385),
  292: new Region('Invercargill City', 505, 0.303),
}

export default class SolarCalculator {
  static estimate(region, district, system, panels) {
    const config = systemConfig[system]
    const regionData = regionConfig[region] || districtConfig[district] || regionConfig[35]

    const totalKwh = panels * regionData.best
    const fullPrice = totalKwh * regionData.price * config.use + totalKwh * config.sell * config.sellPrice
    const seventyPrice = fullPrice * seventy
    return {
      fullPrice: Math.round(fullPrice),
      seventyPrice: Math.round(seventyPrice)
    }
  }
}