import { createContext, useCallback, useContext, useState } from 'react'
import { useBoolean } from 'react-use'

const CalculatorContext = createContext()
export default CalculatorContext

export function useCalculatorContext() {
  return useContext(CalculatorContext)
}

export function useCalculatorState(defaultState = {}) {
  // 是否显示结果
  const [showResult, toggleResult] = useBoolean(false)
  // 表单数据
  const [data, setData] = useState({
    price: defaultState.price,
    deposit: defaultState.deposit
  })
  // 选项数据
  const [frequency, setFrequency] = useState(defaultState.frequency)
  const [loanTerm, setLoanTerm] = useState(defaultState.loanTerm)
  const [interestRate, setInterestRate] = useState(defaultState.interestRate)
  const changeField = useCallback(set => (val => set(val.value)), [])
  return {
    showResult,
    toggleResult,
    data,
    frequency,
    changeField,
    setData,
    setFrequency,
    loanTerm,
    setLoanTerm,
    interestRate,
    setInterestRate
  }
}