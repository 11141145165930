'use client'

import Form from '@component/form'
import FormInput from '@component/form/input'
import FormSelect from '@component/form/select'
import Tooltip from '@component/tooltip'
import IconPoint from '@icon/house/point.svg'
import ImgConsumer from '@image/house/consumer.png'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import { global } from '@store/index'
import { toPrice } from '@utils'
import Image from 'next/image'
import { useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

const carpetOptions = [
  { label: 'I am looking for a low grade carpet', value: 0, price: 51.65 },
  { label: 'I am looking for a medium grade carpet', value: 1, price: 72.15 },
  { label: 'I am looking for a high grade carpet', value: 2, price: 83.1 }
]

export default function FlooringContent({
  floorArea
}) {
  const { mobile } = useRecoilValue(global)
  // 表单数据
  const [formData, setFormData] = useState({
    floor: Math.round(floorArea * 0.8),
    carpet: 1
  })
  const result = useMemo(() => formData.floor * carpetOptions[formData.carpet].price, [formData])
  const handleAreaClick = useCallback(() => {
    propertyDataLayer.flooringResult('property_floor_area')
  }, [])
  const handleCarpetClick = useCallback(() => {
    propertyDataLayer.flooringResult('type_of_carpet')
  }, [])

  return (
    <div className='grid grid-cols-1 gap-y-15 gap-x-30 md:grid-cols-2 items-start pb-20'>
      <Form data={formData} onChange={setFormData} className="space-y-10 md:space-y-18">
        <FormInput
          prop='floor'
          type='number'
          label={
            <span className='flex items-center gap-x-10 text-base'>
              Property floor area
              <Tooltip right={!mobile} top={mobile} dark content={<span className='whitespace-nowrap'>carpet area is based on 80% of the total floor area</span>} contentClassName="rounded-sm p-8 text-xs">
                <IconPoint className="fill-secondary block" />
              </Tooltip>
            </span>
          }
          inputClassName='relative'
          suffix={
            <div className='absolute left-0 top-0 font-thin h-full px-16 flex items-center w-full pointer-events-none'>
              <span className='text-transparent'>{formData.floor}</span>
              {
                formData.floor > 0 && (
                  <span className='-translate-y-2 ml-2'>m<sup>2</sup></span>
                )
              }
            </div>
          }
          onClick={handleAreaClick}
        ></FormInput>
        <FormSelect
          prop='carpet'
          label={
            <span className='flex items-center gap-x-10 text-base'>
              What type of carpet do you need?
              <Tooltip right={!mobile} top={mobile} dark content={<a href='https://www.carpetmill.co.nz/accs' className='whitespace-nowrap' target='_blank'>Learn more about our carpet types &gt;</a>} contentClassName="rounded-sm p-8 text-xs">
                <IconPoint className="fill-secondary block" />
              </Tooltip>
            </span>
          }
          data={carpetOptions}
          onClick={handleCarpetClick}
        ></FormSelect>
        <a href='https://carpetmill.co.nz/contact?utm_source=One+Roof&utm_medium=Email' target='_blank' className='hidden md:flex button-primary-out w-full' onClick={propertyDataLayer.flooringConnectNow}>Book now for a personalised consult</a>
      </Form>
      <section className='shadow-md rounded-md overflow-hidden md:mt-32'>
        <div className='h-8' style={{ background: 'linear-gradient(90deg, #E11B1A 0%, rgba(225, 27, 26, 0.60) 101.39%)' }}></div>
        <div className='px-20 md:px-30 pb-20'>
          <div className='flex items-center justify-between'>
            <span className='text-base text-font'>Total estimated cost is</span>
            <Image src={ImgConsumer} alt="flooring" />
          </div>
          <div className='text-black font-medium text-3xl'>{toPrice(result, '$', '0,0.00')}</div>
          <div className='text-xs text-neutral-5 mt-20'>This estimate is fully inclusive of carpet, underlay, accessories and installation but excludes travel and floor preparation, if required.</div>
        </div>
      </section>
      <a href='https://carpetmill.co.nz/contact?utm_source=One+Roof&utm_medium=Email' target='_blank' className='md:hidden button-primary-out w-full mt-14' onClick={propertyDataLayer.flooringConnectNow}>Book now for a personalised consult</a>
    </div>
  )
}
