'use client'

import IconFlooring from '@icon/house/flooring.svg?url'
import ImgCarpet from '@image/house/carpet-mill.png'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import { isEstimateData, isSaleData } from '@utils/house'
import { find, get, isNumber } from 'lodash-es'
import Image from 'next/image'
import { Suspense } from 'react'
import HomeServicesLayout from '../base'
import FlooringContent from './content'

export default function Flooring({
  data,
  expand = false
}) {
  const councilFloorarea = find(get(data, 'publicRecords.data', []), { alias: 'floorarea' })
  const floorArea = data.floorArea || parseInt(get(councilFloorarea, 'value', 0))

  function handleToggle(opened = false) {
    if (opened) {
      propertyDataLayer.flooringOpened()
    } else {
      propertyDataLayer.flooringClosed()
    }
  }

  return (isSaleData(data) || isEstimateData(data)) && Boolean(floorArea) && (
    <HomeServicesLayout
      title='Flooring'
      icon={IconFlooring}
      right={
        <Image src={ImgCarpet} alt="flooring" className='hidden md:block' />
      }
      secondary={
        <Image src={ImgCarpet} alt="flooring" className='h-42 w-auto -mt-10 md:hidden' />
      }
      onToggle={handleToggle}
      open={expand}
    >
      <Suspense>
        <FlooringContent floorArea={isNumber(floorArea) ? parseInt(floorArea) : 0} />
      </Suspense>
    </HomeServicesLayout>
  )
}