import ImgInsurance from '@image/house/insurance-cover.jpg'
import ImgInsuranceDesc from '@image/house/insurance-desc.png'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import classNames from 'classnames'
import Image from 'next/image'

export default function InsuranceContent() {

  return (
    <section className='flex flex-col gap-36 md:flex-row'>
      <div className='flex-1 min-w-0 flex flex-col gap-y-16'>
        <div className='text-lg font-medium mb-12'>On your side with these great benefits</div>
        <TextRow>
          <b>Natural disaster cover </b>
          for earthquakes, natural landslips, hydrothermal activity, tsunami, natural fires, & volcanic activity.
        </TextRow>
        <TextRow bgc>
          <b>Temporary accommodation for you, your family, and your pets </b>
          if you need to be evacuated from your home.
        </TextRow>
        <TextRow>
          <b>Get replacement keys and locks </b>
          if yours get lost or stolen and pay no excess.
        </TextRow>
        <TextRow bgc>
          <b>Access to </b>
          <a
            href='https://www.ami.co.nz/homehub?utm_medium=widget&utm_source=oneroof_widget&utm_campaign=ami_house_oneroof_widget&utm_content=ami_homehub_link'
            target='_blank'
            className='text-primary underline font-medium'
            onClick={propertyDataLayer.amiHomeHub}
          >
            AMI HomeHub
          </a>
          , our first-class home repairer that brings together a team of experts to take care of your home claim repairs from start to finish.
        </TextRow>
        <TextRow>
          <a
            href='https://www.ami.co.nz/house-insurance?utm_medium=widget&utm_source=oneroof_widget&utm_campaign=ami_house_oneroof_widget&utm_content=ami_house_learn_more_link'
            target='_blank'
            className='text-primary underline font-medium'
            onClick={propertyDataLayer.amiLearnBenefits}
          >
            Learn about these great benefits and more
          </a>
          <div className='text-neutral-5 text-sm mt-4'>*Exclusions and limitations apply. Talk to us about these or refer to the full policy document which can be found on our website.</div>
        </TextRow>
      </div>
      <aside className='flex-1 min-w-0 space-y-14'>
        <div className='shadow-md rounded-md overflow-hidden'>
          <Image
            src={ImgInsurance}
            alt='Insurance Cover'
            width={543}
            height={252}
            unoptimized
            className='w-full h-auto object-cover block'
          />
          <div className='px-30 py-20'>
            <a
              href='https://www.ami.co.nz/house-insurance?utm_medium=widget&utm_source=oneroof_widget&utm_campaign=ami_house_oneroof_widget&utm_content=ami_house_get_quote_btn'
              className='button-primary-out w-full'
              target='_blank'
              onClick={propertyDataLayer.amiConnectNow}
            >
              Get a quote online
            </a>
          </div>
        </div>
        <div className='flex flex-col gap-x-14 md:flex-row md:items-center'>
          <Image
            src={ImgInsuranceDesc}
            alt='Insurance Description'
            width={126}
            height={135}
          />
          <div className='text-sm font-normal'>We’ve been protecting people up and down the motu for over 90 years. Join over 700,000 other New Zealanders and get reassurance that AMI is on your side when you need us.</div>
        </div>
      </aside>
    </section>
  )
}

function TextRow({
  children,
  bgc = false
}) {
  return (
    <div className='flex items-center'>
      <i className='icon icon-arrow-right text-secondary font-bold'></i>
      <i className='icon icon-arrow-right text-secondary font-bold -translate-x-8'></i>
      <div className={classNames('flex-1 min-w-0 text-base', bgc ? 'bg-neutral-1 p-14 rounded-md' : 'ml-14')}>
        {children}
      </div>
    </div>
  )
}
