'use client'

import Form from '@component/form'
import FormRow from '@component/form/row'
import FormSelect from '@component/form/select'
import Select from '@component/select'
import Tooltip from '@component/tooltip'
import ImgHarMoneyAwarded from '@image/house/har-money-awarded.png'
import ImgHarMoney from '@image/house/har-money-circle.png'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import hmyCalculateAmortizationSchedule from '@plugin/harmoney/index2'
import { global } from '@store/index'
import { toPrice } from '@utils'
import { debounce } from 'lodash-es'
import Image from 'next/image'
import Slider from 'rc-slider'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import '/src/styles/common/rsSlider.scss'

const loanTermOptions = [
  { label: '3 years', value: 1, months: 36 },
  { label: '5 years', value: 2, months: 60 },
  { label: '7 years', value: 3, months: 84 }
]
const carpetOptions = [
  { label: 'Excellent', value: 'great' },
  { label: 'Good', value: 'good' },
  { label: 'Average', value: 'average' }
]
const repaymentOptions = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Fortnightly', value: 'fortnightly' },
  { label: 'Monthly', value: 'monthly' },
]
const establishmentFee = 150

export default function HarmoneyContent() {
  const { mobile } = useRecoilValue(global)
  // 表单数据
  const [formData, setFormData] = useState({
    amount: 20000,
    term: 2,
    carpet: 'great',
    repayment: 'monthly'
  })

  const changeLoanAmount = useRef(debounce(() => {
    propertyDataLayer.harmoneyResult('loan_amount')
  }, 1000))
  const handleAmountChange = useCallback((val) => {
    setFormData(d => ({ ...d, amount: val }))
    changeLoanAmount.current()
  }, [setFormData, changeLoanAmount])
  const handleTermChange = useCallback(() => {
    propertyDataLayer.harmoneyResult('loan_term')
  }, [])
  const handleHistoryChange = useCallback(() => {
    propertyDataLayer.harmoneyResult('credit_history')
  }, [])
  const handleRepaymentChange = useCallback((item) => {
    setFormData(d => ({ ...d, repayment: item.value }))
    propertyDataLayer.harmoneyResult('repayment')
  }, [setFormData])

  const result = useMemo(() => {
    const { amount, term, carpet, repayment } = formData
    const months = loanTermOptions[term - 1].months

    hmyCalculateAmortizationSchedule.setCreditProfile(carpet)
    const res = hmyCalculateAmortizationSchedule.calculate(amount, months)
    return res.repayments[repayment]
  }, [formData])

  return (
    <div className='grid grid-cols-1 gap-y-15 gap-x-30 md:grid-cols-2 items-start pb-20'>
      <Form data={formData} onChange={setFormData} className="space-y-10 md:space-y-18">
        <FormRow prop='amount' label={
          <span className='text-base'>
            Select Loan Amount
            <Tooltip dark right content={<div className='px-10 py-8 text-xs'>you can apply to borrow $2,000 - $70,000</div>}>
              <i className='icon icon-info text-secondary ml-4 hidden sm:block'></i>
            </Tooltip>
          </span>
        }>
          <div className='float-right -mt-20 text-lg'>{toPrice(formData.amount, '$', '0,0')}</div>
          <div className='px-12 md:px-0'>
            <Slider
              value={formData.amount}
              onChange={handleAmountChange}
              min={2000}
              max={70000}
              step={1000}
              trackStyle={{ backgroundColor: 'rgba(72, 86, 150, 0.70)', height: 8 }}
              railStyle={{ backgroundColor: '#F0F1F3', height: 8 }}
              handleStyle={{ borderColor: '#485695', borderWidth: 6, width: 24, height: 24, transform: 'translate(-50%, -50%)', boxShadow: 'none !important' }}
            />
          </div>
        </FormRow>
        <FormSelect
          prop='term'
          label={
            <span className='text-base'>Select Loan Term</span>
          }
          data={loanTermOptions}
          onClick={handleTermChange}
        ></FormSelect>
        <FormSelect
          prop='carpet'
          label={
            <span className='text-base'>Credit History</span>
          }
          data={carpetOptions}
          onClick={handleHistoryChange}
        ></FormSelect>
      </Form>
      <aside className='mt-20 md:mt-18'>
        <section className='shadow-md rounded-md'>
          <div className='h-8 rounded-t-md' style={{ background: 'linear-gradient(90deg, #FF4B4A 0%, rgba(255, 75, 74, 0.40) 101.39%)' }}></div>
          <div className='px-20 md:px-30 pb-20'>
            <div className='flex items-center justify-between pt-22'>
              <span className='text-base text-font'>Total estimated repayment is</span>
              <a
                href='https://www.harmoney.co.nz/personal-loans/home-improvements/oneroof?utm_source=oneroof&utm_medium=calculator&utm_campaign=2024'
                target='_blank'
                onClick={propertyDataLayer.harmoneyConnectNow}
              >
                <Image src={ImgHarMoney} alt="harmoney" className='block' />
              </a>
            </div>
            <div className='flex items-start'>
              <span className='text-black font-medium text-[32px]'>{toPrice(result.repayment, '$', '0,0.00')}</span>
              <Select className='text-sm ml-8' value={formData.repayment} data={repaymentOptions} onChange={handleRepaymentChange} />
            </div>
            <div className='text-sm text-font mt-20'>Includes establishment fee of ${establishmentFee} and interest charges totalling {toPrice(result.costOfBorrowing, '$', '0,0')}.</div>
            <a
              href='https://www.harmoney.co.nz/personal-loans/home-improvements/oneroof?utm_source=oneroof&utm_medium=calculator&utm_campaign=2024'
              target='_blank'
              className='button-primary w-full mt-20  !bg-[#FF4B4A]'
              onClick={propertyDataLayer.harmoneyConnectNow}
            >Get a quote</a>
            <div className='text-sm text-neutral-5 mt-20'>**This calculator is an estimate only. Repayments may differ on application, based on a borrower’s individual credit assessment.</div>
          </div>
        </section>
        <div className='mt-20 flex items-center gap-x-16 px-30'>
          <Image src={ImgHarMoneyAwarded} alt="awarded" className='block h-100 w-auto' />
          <div className='flex-1 min-w-0'>
            <div className='text-sm text-black'>Canstar's Most Awarded Personal Loan</div>
            <p className='text-xs text-neutral-5'>Get a renovation loan up to $70K and bring your vision to life. With no need for quotes from tradies, we can approve your home improvement loan quickly and get your renovation started.</p>
          </div>
        </div>
      </aside>
    </div>
  )
}
