import Cover from '@component/cover'
import IconVideo from '@icon/const/video.svg'
import { newsDetail } from '@router'
import { joinStr } from '@utils'
import classNames from 'classnames'
import DisablePrefetchLink from '@component/disablePrefetchLink'

export default function NewsStandard({
  data,
  hubdata,
  ...args
}) {

  const cover = data.coverImg
  return (
    <DisablePrefetchLink {...args} className='block group' href={newsDetail(data, hubdata && hubdata.newsUsePrefix ? joinStr([hubdata.hubType, hubdata.slug], '/') : undefined)} aria-label='go to article'>
      <div className='relative'>
        <Cover src={cover} hoverScale className={classNames('aspect-[267/140] rounded-sm', { 'shadow-md': cover })} alt={data.subject} width={267} height={140} defaultIcon='icon-news-cover'></Cover>
        {
          data.isVideo && (
            <IconVideo className='absolute right-10 top-8'></IconVideo>
          )
        }
      </div>
      <div className='flex justify-between mt-16'>
        <span className='text-secondary text-xs'>{data.category}</span>
        <span className='text-neutral-5 text-xs'>{data.author}</span>
      </div>
      <div className='text-font text-base mt-8 line-clamp-2'>{data.subject}</div>
    </DisablePrefetchLink>
  )
}