'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import Form from '@component/form'
import FormRow from '@component/form/row'
import FormSelect from '@component/form/select'
import Tooltip from '@component/tooltip'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import SolarCalculator from '@plugin/solar'
import { global } from '@store/index'
import { toPrice } from '@utils'
import { find } from 'lodash-es'
import Slider from 'rc-slider'
import { useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import '/src/styles/common/rsSlider.scss'

const sysTypeOptions = [
  { label: 'Solar panels only', value: 1 },
  { label: 'Solar panels with battery', value: 2 },
]
const marks = {
  8: <SliderMark value={8} />,
  10: <SliderMark value={10} />,
  12: <SliderMark value={12} />,
  14: <SliderMark value={14} />,
  16: <SliderMark value={16} />,
  18: <SliderMark value={18} />,
  20: <SliderMark value={20} />,
  22: <SliderMark value={22} />,
  24: <SliderMark value={24} />,
  26: <SliderMark value={26} />,
  28: <SliderMark value={28} />,
  30: <SliderMark value={30} />
}

export default function SloarContent({ regionId, districtId }) {
  const { mobile } = useRecoilValue(global)

  // 表单数据
  const [formData, setFormData] = useState({
    panels: 14,
    sysType: 1
  })

  const handlePanelsChange = useCallback((val) => {
    setFormData(d => {
      propertyDataLayer.solarResult(val, find(sysTypeOptions, item => item.value === d.sysType).label)
      return { ...d, panels: val }
    })
  }, [setFormData])
  const handleSysTypeChange = useCallback((val) => {
    propertyDataLayer.solarResult(formData.panels, val.label)
  }, [formData])

  const result = useMemo(() => {
    const { panels, sysType } = formData
    const res = SolarCalculator.estimate(regionId, districtId, sysType, panels)
    return `${toPrice(res.seventyPrice, '$', '0,0.[00]')} - ${toPrice(res.fullPrice, '$', '0,0.[00]')}`
  }, [formData, regionId, districtId])

  return (
    <div className='grid grid-cols-1 gap-y-15 gap-x-30 md:grid-cols-2 items-start pb-20'>
      <Form data={formData} onChange={setFormData} className="space-y-10 md:space-y-18">
        <FormRow prop='panels' label={
          <span className='text-base'>
            Number of Panels
            <Tooltip dark right={!mobile} bottom={mobile} content={<div className='px-10 py-8 text-xs w-330'>The average New Zealand solar system uses 14 panels. Reduce the number of panels if you are a low power user and need a smaller system, or increase the number of panels if the roof on the property is large enough for additional panels.</div>}>
              <i className='icon icon-info text-secondary ml-4'></i>
            </Tooltip>
          </span>
        }>
          <div className='float-right -mt-20 text-lg'>{formData.panels} Panels</div>
          <div className='px-10'>
            <Slider
              className='child-[.rc-slider-step]:!h-8'
              value={formData.panels}
              onChange={handlePanelsChange}
              min={8}
              max={30}
              step={2}
              marks={marks}
              dotStyle={{ width: 22, height: 22, backgroundColor: '#F0F1F3', color: '#7B7B7B', border: 0 }}
              activeDotStyle={{ backgroundColor: '#7A84B1', color: 'white' }}
              trackStyle={{ backgroundColor: '#7A84B1', height: 8 }}
              railStyle={{ backgroundColor: '#F0F1F3', height: 8 }}
              handleStyle={{ borderColor: '#485695', borderWidth: 6, zIndex: 2, width: 24, height: 24, transform: 'translate(-50%, -50%)', boxShadow: 'none !important' }}
            />
          </div>
        </FormRow>
        <FormSelect
          prop='sysType'
          label={
            <span className='text-base'>
              Select system type?
              <Tooltip dark right={!mobile} bottom={mobile} content={<div className='px-10 py-8 text-xs w-330'>With a Solar Panel Only system you can only use solar energy when the panels are producing power during the daytime. With a Solar Panel with Battery system you can store solar energy to use when the panels are not producing power.</div>}>
                <i className='icon icon-info text-secondary ml-4'></i>
              </Tooltip>
            </span>
          }
          data={sysTypeOptions}
          onChange={handleSysTypeChange}
        ></FormSelect>
        <DisablePrefetchLink
          href='https://lightforce.co.nz/oneroof?utm_source=NZME&utm_medium=Static+&utm_campaign=Oneroof'
          target='_blank'
          className='button-primary-out w-full'
          onClick={propertyDataLayer.solarConnectNow}
        >Get your free quote</DisablePrefetchLink>
      </Form>
      <aside className='mt-20 md:mt-18 shadow-md rounded-md'>
        <div className='h-8 rounded-t-md' style={{ background: 'linear-gradient(90deg, #F3BC51 0%, rgba(243, 188, 81, 0.40) 100%)' }}></div>
        <div className='px-20 md:px-30 py-34'>
          <div className='flex items-center justify-between'>
            <span className='text-base text-font'>Estimated Solar Savings</span>
          </div>
          <div className='flex items-start gap-x-10 mt-24'>
            <span className='text-black font-medium text-[32px]'>{result}</span>
            <span>per year</span>
          </div>
          <div className='text-xs text-neutral-5 mt-24'>Estimate only. Many factors can affect solar power production and cost savings. For a customised quote specific for your property and needs please contact Lightforce Solar for a free consultation.</div>
        </div>
      </aside>
    </div>
  )
}

function SliderMark({
  value,
}) {
  return (
    <div className='h-full text-xs -translate-y-1/2'>{value}</div>
  )
}