'use client'

import CheckboxContent from '@component/form/checkbox/content'
import Menu from '@component/menu'
import classNames from 'classnames'
import { find, isEqual, isFunction, isUndefined, map } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'

export default function Select({
  data,
  value,
  placeholder = 'Select',
  className,
  labelClassName,
  border = false,
  multi = false,
  onChange,
  popWidth,
  prefix
}) {

  // 当前选中的内容
  const [val, setVal] = useState(value || (multi ? [] : undefined))

  const isActive = useCallback((item, val) => {
    if (multi) {
      return !!find(val, v => isEqual(v, item))
    }
    return !isUndefined(val) && item && (isEqual(item, val) || item.value === val || item.value === val.value)
  }, [multi])

  const itemClickHandle = useCallback((item, idx) => {
    return () => {
      if (multi) {
        const idx = val.indexOf(item)
        if (idx === -1) {
          val.push(item)
        } else {
          val.splice(idx, 1)
        }
        const d = [...val]
        setVal(d)
        isFunction(onChange) && onChange(d, item, idx)
      } else {
        setVal(item)
        isFunction(onChange) && onChange(item, idx)
      }
    }
  }, [setVal, onChange, multi, val])

  useEffect(() => {
    if (multi) {
      setVal(value || [])
    } else if (!isActive(val, value)) {
      setVal(value)
    }
  }, [value, val, isActive, multi])
  // 界面上显示的文本
  const label = useMemo(() => {
    let res = placeholder
    if (!isUndefined(val)) {
      if (multi) {
        res = map(val, item => item.label).join(', ')
      } else {
        const cur = find(data, item => isActive(item, val))
        if (cur) res = cur.label
      }
    }
    return res
  }, [val, data, placeholder, multi, isActive])

  const menuData = useMemo(() => map(data, (item, idx) => {
    return <SelectItem key={item.value} onClick={itemClickHandle(item, idx)} active={isActive(item, val)} multi={multi}>{item.label}</SelectItem>
  }), [data, isActive, itemClickHandle, val, multi])

  return (
    <div className={classNames('font-light', className)}>
      <Menu data={menuData} left contentClassName='max-h-300 overflow-auto py-8' width={popWidth}>
        <div className={classNames('text-primary cursor-pointer inline-flex', { 'border px-10 py-4 items-center justify-between rounded-sm': border }, labelClassName)}>
          <span className='self-center flex-1 min-w-0 line-clamp-1'>{prefix}{label}</span>
          <i className='icon icon-arrow-down self-center ml-4'></i>
        </div>
      </Menu>
    </div>
  )
}

function SelectItem({
  children,
  onClick,
  active,
  multi
}) {

  return (
    <div
      onClick={multi ? undefined : onClick}
      className={classNames('text-font text-base font-normal cursor-pointer hover:bg-primary-light hover:text-primary', { '!text-primary': active }, { 'py-8 px-16': !multi })}
    >
      {
        multi
          ? (
            <CheckboxContent checked={active} onChange={onClick} className='!flex py-8 px-16'>{children}</CheckboxContent>
          )
          : children
      }
    </div>
  )
}