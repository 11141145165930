'use client'

import Calculator from '@component/pages/calculator'
import IconLogo from '@icon/const/logo-home-loans.svg?url'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import HomeServicesLayout from '../base'

export default function Banking({
  price,
  utmCampaign,
  expand = false,
}) {

  function handleToggle(opened = false) {
    if (opened) {
      propertyDataLayer.calculatorOpened()
    } else {
      propertyDataLayer.calculatorClosed()
    }
  }

  function handleTitleClick(event) {
    // event.preventDefault()
    event.stopPropagation()
    window.open('https://www.oneroof.co.nz/advice/home-loans')
  }

  return (
    <HomeServicesLayout
      isNew
      icon={IconLogo}
      iconClass='w-auto h-180'
      open={expand}
      onToggle={handleToggle}
      onTitleClick={handleTitleClick}
      title={
        <span className='pl-26 sm:pl-0'>Mortgage Calculator</span>
      }
      titleClass='flex-col sm:-my-16 !items-start sm:!items-center sm:flex-row'
    >
      <Calculator price={price} utmCampaign={utmCampaign}></Calculator>
    </HomeServicesLayout>
  )
}