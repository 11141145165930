'use client'

import IconHarMoney from '@icon/house/har-money.svg?url'
import ImgMortgage from '@image/house/har-money.png'
import { propertyDataLayer } from '@plugin/google/gtm/modules/property'
import Image from 'next/image'
import HomeServicesLayout from '../base'
import HarmoneyContent from './content'

export default function Harmoney({
  expand = false
}) {

  function handleToggle(opened = false) {
    if (opened) {
      propertyDataLayer.harmoneyOpened()
    } else {
      propertyDataLayer.harmoneyClosed()
    }
  }
  return (
    <HomeServicesLayout
      title='Home Improvements Loan calculator'
      icon={IconHarMoney}
      isNew
      right={
        <Image src={ImgMortgage} alt="flooring" className='hidden md:block h-40 w-auto' unoptimized />
      }
      secondary={
        <div className='pb-10 md:pb-0'>
          <Image src={ImgMortgage} alt="flooring" className='h-34 w-auto -mt-10 block md:hidden' unoptimized />
        </div>
      }
      onToggle={handleToggle}
      open={expand}
    >
      <HarmoneyContent />
    </HomeServicesLayout>
  )
}