class Calculator {
  constructor() {
    const config = {
      "initial_loan_amount": 20000,
      "initial_term_in_months": 60,
      "initial_profile_name": "great",
      "minimum_loan_amount": 2000,
      "maximum_loan_amount": 70000,
      "minimum_time_of_repayment": 36,
      "maximum_time_of_repayment": 60,
      "branch": "NZ",
      "featured_profiles": {
        "great": {
          "name": "Excellent",
          "grade": "a1"
        },
        "good": {
          "name": "Good",
          "grade": "b3"
        },
        "average": {
          "name": "Average",
          "grade": "c4"
        }
      },
      "loan_purposes": [
        {
          "id": 7,
          "name": "Debt Consolidation",
          "displayName": "Consolidating my debt",
          "featured": true,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 11,
          "name": "Home Improvements",
          "displayName": "Improving my home",
          "featured": true,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 2,
          "name": "Holiday Expenses",
          "displayName": "Going on holiday",
          "featured": true,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 12,
          "name": "Purchase Used Vehicle",
          "displayName": "Buying a used vehicle",
          "featured": true,
          "securityTypes": [
            "Unsecured",
            "Secured - Pending"
          ]
        },
        {
          "id": 5,
          "name": "Purchase New Vehicle",
          "displayName": "Buying a new vehicle",
          "featured": true,
          "securityTypes": [
            "Unsecured",
            "Secured - Pending"
          ]
        },
        {
          "id": 20,
          "name": "Business Cash Flow",
          "displayName": "Growing my business",
          "featured": true,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 4,
          "name": "Household Items",
          "displayName": "Household items",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 16,
          "name": "Loan to Family Member",
          "displayName": "Loan to family member",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 6,
          "name": "Wedding Expenses",
          "displayName": "Wedding",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 15,
          "name": "Education Expenses",
          "displayName": "Education",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 3,
          "name": "Medical Expenses",
          "displayName": "Medical",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 17,
          "name": "Funeral Expenses",
          "displayName": "Funeral",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 19,
          "name": "Tax Bill",
          "displayName": "Tax bill",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 10,
          "name": "Legal Fees",
          "displayName": "Legal fees",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 14,
          "name": "Computer",
          "displayName": "Computer",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 9,
          "name": "Purchase Boat",
          "displayName": "Boat",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 8,
          "name": "Purchase Caravan",
          "displayName": "Caravan",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        },
        {
          "id": 13,
          "name": "Other",
          "displayName": "Something else",
          "featured": false,
          "securityTypes": [
            "Unsecured"
          ]
        }
      ],
      "rates": {
        "featured_from": 0.0999,
        "featured_secured_from": 0.0989,
        "featured_to": 0.2499,
        "featured_secured_to": 0.2149,
        "a1": 0.0999,
        "a2": 0.1099,
        "a3": 0.1199,
        "a4": 0.1299,
        "a5": 0.1329,
        "b1": 0.1349,
        "b2": 0.1379,
        "b3": 0.1399,
        "b4": 0.1429,
        "b5": 0.1449,
        "c1": 0.1499,
        "c2": 0.1549,
        "c3": 0.1679,
        "c4": 0.1849,
        "c5": 0.1999,
        "d1": 0.2199,
        "d2": 0.2299,
        "d3": 0.2399,
        "d4": 0.2499,
        "d5": 0.2499,
        "e1": 0.2499,
        "e2": 0.2499,
        "e3": 0.2499,
        "e4": 0.2499,
        "e5": 0.2499
      },
      "limits": {
        "featured_from": 2000,
        "featured_to": 70000,
        "a_grade": 70000,
        "b_grade": 70000,
        "c_grade": 67500,
        "d_grade": 49500,
        "e_grade": 42500
      },
      "fees": {
        "platform": 150,
        "dishonour": 15,
        "overdue": 20,
        "service": 0.0125,
        "tiers_by_amount": [
          {
            "platform": 150,
            "min_amount": null,
            "max_amount": 4999
          },
          {
            "platform": 150,
            "min_amount": 5000,
            "max_amount": null
          }
        ]
      },
      "defaults": {
        "a1": 0.0002,
        "a2": 0.0005,
        "a3": 0.0006,
        "a4": 0.0007,
        "a5": 0.0009,
        "b1": 0.0011,
        "b2": 0.0013,
        "b3": 0.0016,
        "b4": 0.002,
        "b5": 0.0025,
        "c1": 0.0032,
        "c2": 0.0042,
        "c3": 0.0055,
        "c4": 0.0073,
        "c5": 0.0096,
        "d1": 0.0124,
        "d2": 0.0159,
        "d3": 0.0201,
        "d4": 0.025,
        "d5": 0.0305,
        "e1": 0.0363,
        "e2": 0.0425,
        "e3": 0.049,
        "e4": 0.0556,
        "e5": 0.0625
      },
      "payment_protect": {
        "fee_rounding": 25,
        "commission": 0.2,
        "management_fee": 0.15,
        "tiers": [
          {
            "min_outstanding_principal": null,
            "max_outstanding_principal": 10000,
            "lender_fee": 0.2
          },
          {
            "min_outstanding_principal": 10000,
            "max_outstanding_principal": 49999,
            "lender_fee": 0.175
          },
          {
            "min_outstanding_principal": 50000,
            "max_outstanding_principal": null,
            "lender_fee": 0.15
          }
        ]
      },
      "var": {
        "static_loss": 0.041
      }
    }
    this.PERIODS = {
      weekly: 52,
      fortnightly: 26,
      monthly: 12
    },
      this.getInterestRate = () => this.LOAN_PRODUCT_CONFIGURATION.rates[this.creditProfile.grade],
      this.calculateRepayments = (t, e, a) => t * e / (1 - Math.pow(1 + e, 0 - a)),
      this.calculateTotalRepayment = (t, e) => Math.abs(-t * e),
      this.LOAN_PRODUCT_CONFIGURATION = config,
      this.FEES = this.LOAN_PRODUCT_CONFIGURATION.fees,
      this.MIN_LOAN_AMOUNT = this.LOAN_PRODUCT_CONFIGURATION.minimum_loan_amount,
      this.MAX_LOAN_AMOUNT = this.LOAN_PRODUCT_CONFIGURATION.maximum_loan_amount,
      this.PROFILES = this.LOAN_PRODUCT_CONFIGURATION.featured_profiles
  }
  setCreditProfile(t) {
    this.creditProfile = this.PROFILES[t],
      this.MAX_LOAN_AMOUNT = this.getMaxLoanAmount() || this.LOAN_PRODUCT_CONFIGURATION.maximum_loan_amount
  }
  getFeeForAmount(t) {
    const e = (this.FEES.tiers_by_amount || []).filter((e => (null === e.min_amount || e.min_amount <= t) && (null === e.max_amount || e.max_amount >= t))).sort(((t, e) => t.platform - e.platform))[0];
    return e && e.platform || this.FEES.platform
  }
  getMaxLoanAmount() {
    const t = this.creditProfile.grade.charAt(0);
    return this.LOAN_PRODUCT_CONFIGURATION.limits[`${t}_grade`]
  }
  calculate(t, e) {
    const a = this.getInterestRate()
      , r = Math.min(this.MAX_LOAN_AMOUNT || null, Math.max(this.MIN_LOAN_AMOUNT || null, t))
      , o = this.getFeeForAmount(r)
      , n = r + o
      , l = ["weekly", "fortnightly", "monthly"].reduce(((t, o) => {
        const l = e / 12 * this.PERIODS[o]
          , i = this.calculateRepayments(n, a * (1 / this.PERIODS[o]), l)
          , s = this.calculateTotalRepayment(i, l) - r;
        return Object.assign(Object.assign({}, t), {
          [o]: {
            repayment: i,
            costOfBorrowing: s
          }
        })
      }
      ), {});
    return {
      amount: r,
      rate: a,
      fee: o,
      repayments: l
    }
  }
}

const calculator = new Calculator()
export default calculator